<template>
  <div>
    <div class="placeholder">
      <div class="row">
        <div class="col-6">
          Oops, it looks like you're lost.
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "404",
  data() {
    return {};
  },
  components: {
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
</style>